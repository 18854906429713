import axios from "axios";
import qs from 'qs';

import API from "./api";
import apiAddress from '../config/index'
const ApiServe = {

    wallet: null,

    http(methodName,params={},requestType='post') {
        const instance = axios.create({
            timeout: 60000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            },
        });

        instance.interceptors.request.use(
            (config) => {

                let newParams = JSON.stringify(params);
                config.params = requestType === 'get' || requestType === ''
                    ? { p: newParams }
                    : null;

                config.data = requestType === 'post' || requestType === ''
                    ? qs.stringify({ p: newParams })
                    : null;

                return config;
            },
            (error) => Promise.reject(error)
        );

        instance.interceptors.response.use(res => {
            return this.resSuccess(res);
        },err => {
            console.log(this.resError(err));
            return this.resError(err);
        });

        // window.common.loading(true)

        // if(requestType === 'open'){
        //     return window.open(
        //         this.wallet.apiAddress +
        //         API[methodName] +
        //         '?' +
        //         qs.stringify({ p: JSON.stringify(params) })
        //     )
        // }

        return instance.request({
            url: API[methodName],
            method: requestType,
            baseURL: apiAddress
        });

    },

    resSuccess(response){

        // if (process.env.NODE_ENV === 'development') console.info(`${response.config.url}响应信息:`, response);

        const { info,code, msg } = response.data;

        if(code === '99'){
            return Promise.reject({ msg: msg || code, code });

        }

        if (code === '0' || code === '4') {
            const newData = info;
            return newData || {};
        } else {
            return Promise.reject({ msg: msg || code, code });
        }
    },

    resError(error){


        if(error instanceof Error){
            return Promise.reject({ msg: '网络异常！', code: -1 });
        }

        if(!error && !error.response){
            return Promise.reject({ msg: '网络异常！', code: -1 });
        }

        if (process.env.NODE_ENV === 'development') console.error(`接口${error.response.config.url}响应错误信息:`, error.response);
        return Promise.reject({msg: error.response.statusText || '', code: error.response.status});

    },



    request(methodName,params={},requestType='post',wallet) {

        const instance = axios.create({
            timeout: 60000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            },
        });

        instance.interceptors.request.use(
            (config) => {

                let arg =  JSON.stringify(params);
                config.params = requestType === 'get' || requestType === ''
                    ? { p: arg }
                    : null;

                config.data = requestType === 'post' || requestType === ''
                    ? qs.stringify({ p: arg })
                    : null;

                return config;
            },
            (error) => Promise.reject(error)
        );

        instance.interceptors.response.use(res => {
            return res.data
        },err => {
            return {code: 403};
        });

        return instance.request({
            url: API[methodName],
            method: requestType,
            baseURL: apiAddress
        });

    },

    requestUrl(url,methodName,params={},requestType='post') {

        const instance = axios.create({
            timeout: 60000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            },
        });

        instance.interceptors.request.use(
            (config) => {
                // const currentVersion = common.storage('VERSION')==='lite' ? 2:1
                const paramsAll =  Object.assign(params);
                config.params = requestType === 'get' || requestType === ''
                    ? paramsAll
                    : null;

                config.data = requestType === 'post' || requestType === ''
                    ? qs.stringify({ p: paramsAll })
                    : null;

                return config;
            },
            (error) => Promise.reject(error)
        );

        instance.interceptors.response.use(res => {
            return res.data
        },err => {
            return {code: 500};
        });


        return instance.request({
            url: methodName,
            method: requestType,
            baseURL: url
        });

    },




}

export default ApiServe;