/*
 * @Author: jack-ouyang
 * @Email: jiawangouyang@pawnfi.com
 * @Date: 2022-01-24 17:04:38
 * @Last Modified by: jack-ouyang
 * @Last Modified time: 2022-02-22 15:52:19
 * @Description: Description
 */

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import './index.scss'
import HeaderBack from '@/components/back'
import { useEffect, useState } from 'react'
import { getLangObject } from '../../../../i18n/langUtil'
const MediaDetaile = (props) => {
    const location = useLocation()
    const detaile = location.state
    const history = useNavigate()
    const [lang, setLang] = useState('zh_cn')
    // const [ searchParams ] = useSearchParams()
    console.log('searchParams',location)
    useEffect(()=> {
        getLang()
    }, [])
    const getLang = () => {
        setLang(getLangObject().value === 'cn' ? 'zh_cn' : 'en')
    }
    // <span dangerouslySetInnerHTML={{__html: this.state.content}}></span>
    return(
        <div className="p-media-detaile">
            <HeaderBack />
            <div className="detail-centent">
                <img src={detaile.f_cover_url} alt="" className="detail-title-img"/>
                <div className="userInfo flex align-center mb-6">
                    {/* <img src="" alt="" /> */}
                    <div className="user-img"></div>
                    <div className="ml-4">
                        <div className="fs-2 fw-b">DREP Foundadom</div>
                        <div className="fs-2 fw-m">{detaile.f_update_time}</div>
                    </div>
                </div>
                <h1 className="fs-4 fw-b mb-7">{detaile['f_title_' + lang]}</h1>
                <p className="mb-7 fs-2 fw-m text-secondary" dangerouslySetInnerHTML={{__html: detaile['f_text_' + lang]}}></p>

                <div className="fs-2 fw-b mb-6">DREP简介：</div>

                <div className="subcentent pa-6 mb-6">
                    <p className="fs-2 fw-m text-secondary mb-6" >{detaile['f_desc_' + lang]}</p>
                    <div className="sub-img-box">
                        <div className="px-4 sub-img">
                            <img src={require('@/style/images/media/detaile/sub_img1.png')} alt="" />
                        </div>
                        <div className="px-4 sub-img">
                            <img src={require('@/style/images/media/detaile/sub_img2.png')} alt="" />
                        </div>
                        <div className="px-4 sub-img">
                            <img src={require('@/style/images/media/detaile/sub_img3.png')} alt="" />
                        </div>
                        <div className="px-4 sub-img">
                            <img src={require('@/style/images/media/detaile/sub_img4.png')} alt="" />
                        </div>
                        <div className="px-4 sub-img">
                            <img src={require('@/style/images/media/detaile/sub_img5.png')} alt="" />
                        </div>
                        <div className="px-4 sub-img">
                            <img src={require('@/style/images/media/detaile/sub_img6.png')} alt="" />
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    )
}

export default MediaDetaile