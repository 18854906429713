import React, { useEffect } from "react";
import * as echarts from 'echarts';
import { useRef } from "react";
import Decimal from 'decimal.js';
function getGrowth(list){
  let len = list.length
  let last = list[len-1]
  let before = list[len-2]
  return Decimal.mul(Decimal.div(Decimal.sub(last,before),before),100).toFixed(2)+'%'
}
export default function Linecharts({data,color,latest}) {
    const chart = useRef(null)
    let min = !data?0:Decimal.min(...data?.y).valueOf()
    let max = !data?0:Decimal.max(...data?.y).valueOf()
    const showChart = (ele,x,y)=>{
        let mychart = echarts.init(ele)
        let option = {
          grid:{ // 让图表占满容器
            top:"0px",
            left:"0px",
            right:"0px",
            bottom:"0px"
          },
          xAxis: {
            type: 'category',
            data: x,
            show: false
          },
          yAxis: {
            type: 'value',
            show: false,
            min,
            max
          },
          series: [
            {
              showSymbol: false,
              data: y,
              type: 'line',
              symbolSize:1,
              markLine: { silent: true },
              color: color,
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: color
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 0, 0, 0)'
                  }
                ])
              }
            }
          ],
          tooltip: {
            position: function (pt) {
              return [pt[0], '10%'];
            },
              trigger: 'axis',
              backgroundColor: 'rgba(32, 33, 36,.7)',
              borderColor: 'rgba(32, 33, 36,0.20)',
             borderWidth: 1,
             valueFormatter:val=>'$ '+Decimal(val).toFixed(2),
              textStyle: {
                color: '#fff',
                fontSize: '12'
              }
           }
        }
        mychart.setOption(option)
    }
    useEffect(() => {
      showChart(chart.current,data?.x,data?.y)
      return () => {
      }
    }, [data])


    const mtMouseOver = (e) => {
      //  悬停动画
      const _target = e.target
      _target.style.animation = 'wordAnimation 380ms ease-in-out forwards'
  
      _target.addEventListener('animationend', () => {
          _target.style.animation = null
      })
  }
    let latestPercent = (latest.priceChangePercent?Decimal(latest.priceChangePercent):0).toFixed(2)

    return (
        <div className="bar-chart-container">
          <div className="chart" ref={chart} ></div>
          {!data?0:<div className="market" >
            <p className="current"  onMouseOver={mtMouseOver}>{"$"+(latest.lastPrice?Decimal(latest.lastPrice).toFixed(2):'----')}</p>
            <p className="change" style={{color:color}}>{(Decimal(latestPercent).gte(0)?'+':'')+latestPercent+'%'}</p> 
          </div>}
        </div>
    )
}
