

const PagingList = (props) => {
    const {list, indexNew} = props
    console.log(indexNew)
    const change = (index) => {
        let num = indexNew
        if(index === indexNew){

        }else if(index >= 0){
            props.onChange && props.onChange(index)
        }else if(index === -1 && indexNew !== 0){
            props.onChange && props.onChange(--num)
        }else if(index === -2 && indexNew !== list.length - 1){
            props.onChange && props.onChange(++num)
        }
        
    }
    return (
        <div className="flex align-center justify-between">
            <span onClick={() => change(-1)} className={`mr-7 cursor fs-3 ${ indexNew === 0 ? 'text_default' : 'text_primary'}`}>{'<'}</span>
            <div className="">
                {
                    list.map((item,index)=> (
                        <span onClick={() => change(index )} key={index} className={`cursor fs-2 mr-7 ${ indexNew === index ? 'text_primary fs-3' : 'text_default'}`}>{index + 1}</span>
                    ))
                }
            </div>
            <span onClick={() => change(-2)} className={`fs-3 cursor ${ indexNew === list.length-1 ? 'text_default' : 'text_primary'}`}>{'>'}</span>
        </div>
    )
}

export default PagingList