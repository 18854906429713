import Nav from "../app/nav"
import { useTranslation } from "react-i18next"
import './index.scss'
import  drepbg from '@/style/images/home/drepBg.png'
import  drep from'@/style/images/home/drep.png'
const DrepPage = (props) => {
    const { swiperIndex } = props
    const {t}=useTranslation()
    return (
        <div className="p-drep-page h100">

            <Nav />
            <div className="drep-container">
                <div className="flex drep-container-box">
                    <img className="container-img" src={drepbg}  alt="" />
                    <div className="container-title">
                        <div className={`title-num fw-b ${swiperIndex === 1 ? 'animation-name' : 'transform'} `}>01</div>
                        <div className={`title fw-b mt-4 ${swiperIndex === 1 ? 'animation-name' : 'transform'}`}>{t('drep.302')}</div>
                        <div className="ani-title-container mt-5">
                            <div className={`ani-bottom-division ${swiperIndex === 1 ? 'animation-name' : 'transform'} `}></div>
                        </div>
                        <div className={`my-6 ${swiperIndex === 1 ? 'animation-name' : 'transform'} `}>
                        {t('drep.303')}
                        </div>
                        <div className={`mb-6 ${swiperIndex === 1 ? 'animation-name' : 'transform'} `}>
                        {t('drep.304')}
                        </div>
                        <div className={`${swiperIndex === 1 ? 'animation-name' : 'transform'} `}>
                        { t('drep.305')}
                        </div>
                    </div>
                </div>
                <img className="footer-img" src={drep} />
            </div>

            <div className={`triangle-first s-hide triangle ${swiperIndex === 1 ? 'page1' : 'page1out'} `}>
                <svg viewBox="0 0 429 439">
                    <defs>
                    <filter x="-26.0%" y="-20.7%" width="152.0%" height="150.9%" id="filter">
                        <feOffset result="shadowOffsetOuter1" dy="16"></feOffset>
                        <feGaussianBlur result="shadowBlurOuter1" stdDeviation="26" in="shadowOffsetOuter1"></feGaussianBlur>
                        <feColorMatrix values="0.03 0 0 0 0 0 0.04 0 0 0 0 0 0.05 0 0 0 0 0 1 0" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                    </defs>
                    <g filter="url(#filter)">
                    <path fill="#fff" fillOpacity="0.1"  d="M195.450075,48.5625 C205.825075,27.8125 222.646075,27.8125 233.021075,48.5625 L375.451075,333.4205 C385.825075,354.1705 375.434075,370.9915 352.234075,370.9915 L76.2370753,370.9915 C53.0400753,370.9915 42.6460753,354.1715 53.0210753,333.4205 L195.450075,48.5625 Z" id="path"></path>
                    </g>
                    <path fill="#fff" fillOpacity="1"  d="M195.450075,48.5625 C205.825075,27.8125 222.646075,27.8125 233.021075,48.5625 L375.451075,333.4205 C385.825075,354.1705 375.434075,370.9915 352.234075,370.9915 L76.2370753,370.9915 C53.0400753,370.9915 42.6460753,354.1715 53.0210753,333.4205 L195.450075,48.5625 Z" id="path"></path>
                </svg>
            </div>
            <div className={`triangle-second s-hide triangle ${swiperIndex === 1 ? 'page1' : 'page1out'}`}>
                <svg viewBox="0 0 429 439">
                    <defs>
                    <filter x="-26.0%" y="-20.7%" width="152.0%" height="150.9%" id="filter">
                        <feOffset result="shadowOffsetOuter1" dy="16"></feOffset>
                        <feGaussianBlur result="shadowBlurOuter1" stdDeviation="26" in="shadowOffsetOuter1"></feGaussianBlur>
                        <feColorMatrix values="0.03 0 0 0 0 0 0.04 0 0 0 0 0 0.05 0 0 0 0 0 1 0" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                    </defs>
                    <g filter="url(#filter)">
                    <path fill="#fff" fillOpacity="0.1" d="M195.450075,48.5625 C205.825075,27.8125 222.646075,27.8125 233.021075,48.5625 L375.451075,333.4205 C385.825075,354.1705 375.434075,370.9915 352.234075,370.9915 L76.2370753,370.9915 C53.0400753,370.9915 42.6460753,354.1715 53.0210753,333.4205 L195.450075,48.5625 Z" id="path"></path>
                    </g>
                    <path fill="#fff" fillOpacity="1" d="M195.450075,48.5625 C205.825075,27.8125 222.646075,27.8125 233.021075,48.5625 L375.451075,333.4205 C385.825075,354.1705 375.434075,370.9915 352.234075,370.9915 L76.2370753,370.9915 C53.0400753,370.9915 42.6460753,354.1715 53.0210753,333.4205 L195.450075,48.5625 Z" id="path"></path>
                </svg>
            </div>
        </div>
    )
}

export default DrepPage