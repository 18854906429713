import { useState } from 'react'
import { Drawer } from 'antd';
import { langList, setLangObject } from '@/i18n/langUtil'
import Footer from '../../footer';
import './navBtn.scss'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next"

const NavBtn = (props) => {

    const lang = JSON.parse(window.localStorage.getItem('LANGUAGE'))
    const [isMune, setIsMune] = useState(false)
    const mtSetLanguge = (item) => {
        setLangObject(item)
    }
    const mtMouseOver = (e) => {
        //  悬停动画
        const _target = e.target
        _target.style.animation = 'wordAnimation 380ms ease-in-out forwards'

        _target.addEventListener('animationend', () => {
            _target.style.animation = null
        })
    }
    const langStyle = (item) => {
        let className = ''
        if (props.dark) {
            className = lang.title === item.title ? 'white_text' : 'dark_lang'
        } else {
            className = lang.title === item.title ? 'text_primary' : 'text_low'
        }
        return className
    }
    return (
        <div className="flex align-center moudle_navBtn">
            {/* {
                langList.map((item, index) => 
                    <div key={index}>
                        <span className={`fs-3 mr-4 cursor inline-block ${langStyle(item)}`} onClick={()=> mtSetLanguge(item)} onMouseOver={mtMouseOver}>{item.title}</span>
                        {
                            index !== langList.length - 1 &&
                            <span className={`fs-3 mr-4 cursor ${ props.dark ? 'white_text': 'text_primary'}`}>/</span>
                        }
                    </div>
                )
            } */}
            <div className="c-navbtn">
                <span className={`iconfont icon-icon-nav is_4 cursor ${props.dark ? 'white_text' : 'text_primary'}`} onClick={() => setIsMune(true)}></span>
                <MuneModal isMune={isMune} click={() => setIsMune(false)} />
            </div>
        </div>
    )
}

const MuneModal = (props) => {
    const { isMune } = props
    const [isShow, setIsShow] = useState(1)
    const { t } = useTranslation()

    const mune = [
        { name: t('home.203'), link: '', router: '/', type: 1 },
        {name: 'Staking',link: 'https://www.lookfor.io/lfg', router: '', type: 2},
        {name: 'NFT', link: '', router: '', type: 108},
        // {name:t('nav.104'),link:'',router: '',type: 105},
        // { name: t('nav.106'), link: '', router: '', type: 106 },
        {name:'Chain',link:'',router: '',type: 109},
        // { name: t('nav.105'), link: 'https://drep-foundation.medium.com/', router: '/media', type: 2 },
        {name:t('Community'),link:'',router: '',type: 107},
        { name: t('home.204'), link: '', router: '/resource', type: 1 },
        { name: t('home.206'), link: '', router: '/privacy', type: 1 },
        { name: t('home.207'), link: 'https://github.com/drep-project/one-paper/blob/master/WEBSITE%20TERMS%20OF%20USE.pdf', router: '', type: 2 },
    ]
    const history = useNavigate()
    const mtHistory = () => {
        history('/')
        onClose()
    }
    const spotMoveEvent = (show) => {
        setIsShow(show)
    }
    const onClose = () => {
        props.click && props.click()
    }
    const mtTobtn = (item) => {
        onClose()
        switch (item.type) {
            case 1:
                history(item.router)
                break;
            case 2:
                window.open(item.link)
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <Drawer
                placement="top"
                // width={375}
                height={'100%'}
                onClose={onClose}
                closable={false}
                visible={isMune}
            >
                <div className="p-mune p-relative h100">
                    <div className="flex justify-between align-center">
                        <img src={require("@/style/images/drep_img/menuLogo.png")} alt="" onClick={mtHistory} className="cursor logo" />
                        <span className="iconfont icon-icon-close1 close cursor" onClick={onClose}></span>
                    </div>
                    <div className="s-hide">
                        <div className="flex justify-between p-center w100 pc-box">
                            <div className="cursor" onClick={() => history('/')} onMouseEnter={() => spotMoveEvent(1)}>
                                <div className="fs-5">{t('home.203')}</div>
                                {
                                    isShow === 1 &&
                                    <div className="spot" />
                                }
                            </div>
                            <div className="cursor" onClick={() => history('/resource')} onMouseEnter={() => spotMoveEvent(2)}>
                                <div className="fs-5">{t('home.204')}</div>
                                {
                                    isShow === 2 &&
                                    <div className="spot" />
                                }
                            </div>
                        </div>
                        <div className="footer-box">
                            <div className="flex justify-center align-center">
                                <span className="fs-3 mr-7 text_default">{t('home.205')}</span>

                                <a href="https://t.me/drep_foundation" target="_blank">
                                    <div className="telegram"></div>
                                </a>
                                <span className="fs-3 mr-7 text_default">{t('home.209')}</span>

                                <a href="https://drep-foundation.medium.com/" target="_blank">
                                    <div className="medium"></div>
                                </a>

                                <a href="https://twitter.com/DrepOfficial" target="_blank">
                                    <div className="twitter"></div>
                                </a>

                                <span className="fs-3 mr-7 text_default cursor" onClick={() => history('/privacy')}>{t('home.206')}</span>
                                <span className="fs-3 text_default cursor" onClick={() => window.open('https://github.com/drep-project/one-paper/blob/master/WEBSITE%20TERMS%20OF%20USE.pdf')}>{t('home.207')}</span>
                            </div>
                            <Footer contentshow={!isMune} />
                        </div>
                    </div>
                    <div className="l-hide pt-6">
                        {
                            mune.map((item, index) => {
                                let dom = <div className="mb-6 fs-3" onClick={() => mtTobtn(item)} key={index}>{item.name}</div>
                                if (item.type === 106) {
                                    dom = <Item title={item.name} />
                                }
                                if(item.type === 105){
                                  dom = <TokenMenu title={item.name} />
                                }
                                if(item.type === 108){
                                  dom = <NftMenu title={item.name} />
                                }
                                if(item.type === 107){
                                  dom = <CommunityMenu title={item.name} />
                                }
                                if(item.type === 109){
                                  dom = <ChainMenu title={item.name} />
                                }
                                
                                return dom
                            }
                            )
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
function TokenMenu (props){
  const { t } = useTranslation()
    const { title } = props
    const [show, setShow] = useState(false)
    return (
        <div className='mb-exchange'>
            <div className='mb-6 fs-3 text_primary flex justify-between' onClick={() => setShow(!show)}>
                <span className='title'>{title}</span>
                <img src={require('@/style/images/home/icon-DropDown.png')} width={24} className={`${show ? 'rotate' : ''}`} alt="" />
            </div>
            {
                show && (
                    <div>
                        <div>
                            <span className='py-5 inline-block fs-2 text-8b8b91'>
                              Chain
                            </span>
                        </div>
                        <div className='mb-item'>
                            <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://etherscan.io/token/0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2">
                                <img src={require("@/style/images/home/icon_eth.png")} className='mr-3' width={32} alt="" />
                                <div>
                                    <p className=' fs-2 fw-b'>ETH</p>
                                </div>
                            </a>
                        </div>
                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://bscscan.com/token/0xec583f25a049cc145da9a256cdbe9b6201a705ff">
                              <img src={require("@/style/images/home/icon-binance.png")} className='mr-3' width={32} alt="" />
                              <div>
                                  <p className=' fs-2 fw-b'>BSC</p>
                                  </div>
                          </a>
                        </div>
                    </div>
                )

            }
        </div>
    )
}
function ChainMenu (props){
  const { t } = useTranslation()
    const { title } = props
    const [show, setShow] = useState(false)
    return (
        <div className='mb-exchange'>
            <div className='mb-6 fs-3 text_primary flex justify-between' onClick={() => setShow(!show)}>
                <span className='title'>{title}</span>
                <img src={require('@/style/images/home/icon-DropDown.png')} width={24} className={`${show ? 'rotate' : ''}`} alt="" />
            </div>
            {
                show && (
                    <div className="pl-5">
                        <div className='mb-item'>
                            <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://explore-testnet.drep.org/">
                                {/* <img src={require("@/style/images/home/icon_eth.png")} className='mr-3' width={32} alt="" /> */}
                                <div>
                                    <p className=' fs-2 fw-b'>Drepscan</p>
                                </div>
                            </a>
                        </div>
                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://faucet-testnet.drep.org/">
                              {/* <img src={require("@/style/images/home/icon-binance.png")} className='mr-3' width={32} alt="" /> */}
                              <div>
                                  <p className=' fs-2 fw-b'>Faucet</p>
                                  </div>
                          </a>
                        </div>
                    </div>
                )

            }
        </div>
    )
}
function NftMenu (props){
  const { t } = useTranslation()
    const { title } = props
    const [show, setShow] = useState(false)
    return (
        <div className='mb-exchange'>
            <div className='mb-6 fs-3 text_primary flex justify-between' onClick={() => setShow(!show)}>
                <span className='title'>{title}</span>
                <img src={require('@/style/images/home/icon-DropDown.png')} width={24} className={`${show ? 'rotate' : ''}`} alt="" />
            </div>
            {
                show && (
                    <div>
                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://blur.io/collection/lookfor-gem">
                                <svg className="icon is_5 mr-4" aria-hidden="true">
                                  <use xlinkHref={`#icon-icon-Blur1`}></use>
                                </svg>
                                <div>
                                    <p className=' fs-2 fw-b'>Trade LookFor Gem</p>
                                    </div>
                          </a>
                        </div>
                        
                        {/* <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://bscscan.com/token/0xec583f25a049cc145da9a256cdbe9b6201a705ff">
                              <img src={require("@/style/images/home/icon-binance.png")} className='mr-3' width={32} alt="" />
                              <div>
                                  <p className=' fs-2 fw-b'>BSC</p>
                                  </div>
                          </a>
                        </div> */}
                    </div>
                )

            }
        </div>
    )
}
function Item(props) {
    const { t } = useTranslation()
    const { title } = props
    const [show, setShow] = useState(false)
    return (
        <div className='mb-exchange'>
            <div className='mb-6 fs-3 text_primary flex justify-between' onClick={() => setShow(!show)}>
                <span className='title'>{title}</span>
                <img src={require('@/style/images/home/icon-DropDown.png')} width={24} className={`${show ? 'rotate' : ''}`} alt="" />
            </div>
            {
                show && (
                    <div>
                        <div>
                            <span className='py-5 inline-block fs-2 text-8b8b91'>
                                Buy $DREP
                            </span>
                        </div>
                        <div className='mb-item'>
                            <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://www.binance.com/en/trade/DREP_USDT?theme=dark&type=spot">
                                <img src={require("@/style/images/home/icon-binance.png")} width={32} alt="" />
                                <span className='ml-3 fs-2 fw-b'> Binance</span>
                            </a>
                        </div>
                        {/* <div className='mb-item'>
                            <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://www.coinbase.com/price/drep-new">
                                <img src={require("@/style/images/home/icon-Coinbase.png")} width={32} alt="" />
                                <span className='ml-3 fs-2 fw-b'>Coinbase</span>
                            </a>
                        </div> */}
                        <div className='mb-item'>
                            <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://www.gate.io/trade/DREP_USDT">
                                <img src={require("@/style/images/home/icon-gate.png")} width={32} alt="" />
                                <span className='ml-3 fs-2 fw-b'> Gate.io</span>
                            </a>
                        </div>
                    </div>
                )

            }
        </div>
    )
}

function CommunityMenu(props){
  const { t } = useTranslation()
    const { title } = props
    const [show, setShow] = useState(false)
    return (
        <div className='mb-exchange'>
            <div className='mb-6 fs-3 text_primary flex justify-between' onClick={() => setShow(!show)}>
                <span className='title'>{title}</span>
                <img src={require('@/style/images/home/icon-DropDown.png')} width={24} className={`${show ? 'rotate' : ''}`} alt="" />
            </div>
            {
                show && (
                    <div className="pl-5">
                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://twitter.com/DrepOfficial">
                                <div>
                                    <p className=' fs-2 fw-b'>Twitter</p>
                                </div>
                          </a>

                        </div>

                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://t.me/drep_foundation">
                                <div>
                                    <p className=' fs-2 fw-b'>Telegram</p>
                                </div>
                          </a>

                        </div>

                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://discord.com/invite/lookfor">
                                <div>
                                    <p className=' fs-2 fw-b'>LookFor Discord</p>
                                </div>
                          </a>

                        </div>

                        <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://drep-foundation.medium.com/">
                                <div>
                                    <p className=' fs-2 fw-b'>Medium</p>
                                </div>
                          </a>

                        </div>

                        {/* <div className='mb-item'>
                          <a target="_blank" rel="noopener noreferrer" className='text_primary flex align-center py-5' href="https://bscscan.com/token/0xec583f25a049cc145da9a256cdbe9b6201a705ff">
                              <img src={require("@/style/images/home/icon-binance.png")} className='mr-3' width={32} alt="" />
                              <div>
                                  <p className=' fs-2 fw-b'>BSC</p>
                                  </div>
                          </a>
                        </div> */}
                    </div>
                )

            }
        </div>
    )
}

export default NavBtn