import './index.scss'

const NewItem = (props) => {
    const { data, index, lang } = props
    const click = () => {
        props.click && props.click()
    }
    return (
        <div className="news-list-item-box" key={index}>
            <div className="news-list-item flex column align-center cursor" onClick={()=> click()}>
                <img src={data.f_cover_url} alt="" />
                <div className='mt-4 mb-2 t-es-2 fs-3 fw-b w100'>{data['f_title_' + lang]}</div>
                <div className="flex align-center w100 mb-4">
                    <img src={require('@/style/images/media/Vector.png')} alt="" width="12px" />
                    <span className="fs-2 fw-m text-secondary ml-3">{data['f_update_time']?.substring(0,10)}</span>
                </div>
                <p className="t-es-2 text-secondary fs-2 fw-m w100">{data['f_desc_' + lang]}</p>
            </div>
        </div>
    )
}

export default NewItem