import BanPath from '../../style/images/home/1123.png'
import IconPath from '../../style/images/home/icon-drep.png'

import './index.scss'
export default function BanIp(){
  return <div className="ban-ip">
    <img src="" alt="" />
      <img className='ban-img' src={BanPath} alt="" />
      <img className='icon-img' src={IconPath} alt="" />
      </div>
}