export const langList = [
    { label: "English", value: 'en', title: 'EN'},
    // { label: "中文", value: 'cn', title: 'CN'},
]

export const defaultLang = langList[0];

export const getLangObject = ()=>{

    const local = window.localStorage.getItem('LANGUAGE');
    let lang = defaultLang;

    if(!local || typeof local !== 'string' || local.length < 10){
        window.localStorage.setItem('LANGUAGE', JSON.stringify(lang));
        return lang;
    }

    try {
        lang = JSON.parse(local);
    } catch(e){
        lang = { label: "中文", value: 'cn'};
    }
    window.localStorage.setItem('LANGUAGE', JSON.stringify(lang));
    return lang;
}

export const setLangObject = (obj) => {
    window.localStorage.setItem('LANGUAGE', JSON.stringify(obj));
    window.location.reload();
};
