
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import NewItem from '../app/newItem/index'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderBack from '@/components/back'
import Nav from '@/pages/home/app/nav'
import './list.scss'
import ApiServe from '../../../service/handleServe'
import { getLangObject } from '../../../i18n/langUtil'
const Swiper = window.Swiper
const MediaList = () => {
    const history = useNavigate()
    const [ newsList, setNewsList] = useState([])
    const bannerList = []
    const [hasMore, setHasMore] = useState(true)
    const [totalCount, setTotalCount] = useState(20)
    const [page, setPage] = useState(0)
    const [lang, setLang] = useState('zh_cn')
    const getLang = () => {
        setLang(getLangObject().value === 'cn' ? 'zh_cn' : 'en')
    }
    const handleInfiniteOnLoad = () => {
        if (newsList.length >= totalCount) {
            setHasMore(false)
            return;
        }
        console.log('handleInfiniteOnLoad')
        getNewsList(page + 1)
        setPage(page + 1)
    }
    useEffect(() => {
        console.log('请求数据==')
        getLang()
    }, [])
    const getNewsList = (curpage) => {
        ApiServe.http('GET_SELECTNEW_INFO',{
            page_size: 10,
            page: curpage
        }).then(res => {
            console.log('res==',res)
            setNewsList([...newsList, ...res.data])
            setTotalCount(res.totalcount)
            setPage(res.totalpage)
        }).catch(err => {
            console.log('err==',err)
        })
        
    }
    const toDetaile = (item) => {
        if(item.f_article_type === '1'){
            window.open(item['f_link_url_' + lang])
        }else{
            history('/media/detaile', {state: item})
        }
    }
    useEffect(()=>{
        new Swiper('.media-list-swiper', {
            observer:true,
            loop: true,
            // loopAdditionalSlides: 0,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
            },
            pagination: {
                el: '.swiper-pagination-media-list',
            },
        })
    }, [])
    return (
        <div className="p-media-list">
            <HeaderBack />
            <div className="media-list">
                <div className="media-swiper">
                    <div className="swiper-container media-list-swiper">
                        <div className="swiper-wrapper ">
                            {
                                newsList.slice(0,4).map((item, index) => 
                                    <div className="swiper-slide" key={index}>
                                            <img src={item.f_cover_url} alt=""/>
                                    </div>
                                )
                            }
                        </div>
                        <div className="swiper-pagination-media-list"></div>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={newsList.length}
                    next={handleInfiniteOnLoad}
                    hasMore={hasMore}
                    // height={400}
                >
                    <div className="flex flex-warp media-list-item-box">
                        {
                            newsList.map((item, index) =>
                                <Fragment key={index} >
                                    <NewItem data={item} lang={lang} index={index} click={() => toDetaile(item)} />
                                </Fragment>
                            )
                        }
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default MediaList