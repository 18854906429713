
import { BrowserRouter } from "react-router-dom";
import RouterBefore from "./routerConfig";
import useBeforeRouter from "./useBeforeRouter"
import path from '../style/images/home/1123.png'
import BanIp from "./BanIp";

const AppRouter = () => {
    const flag = useBeforeRouter()
    return (
        <BrowserRouter>
            {flag?<div className="page light">
                <RouterBefore />
            </div>:<BanIp/>}
        </BrowserRouter>
    )
}

export default AppRouter