
import Nav from '../home/app/nav'
import './index.scss'
import { v4 as uuidv4 } from "uuid"
import Footer from '../footer/index'
import { useTranslation } from "react-i18next"


const Resource = () => {
    const {t}=useTranslation()
    const linkList = [{
        title: 'Whitepaper EN',
        address: 'https://raw.githubusercontent.com/drep-project/one-paper/master/DREP%20WhitePaper_EN.pdf'
    }, {
        title: 'Whitepaper CN',
        address: "https://github.com/drep-project/one-paper/raw/master/DREP%20WhitePaper_CN.pdf"
    },
    {
        title: "Whitepaper KR",
        address: "https://raw.githubusercontent.com/drep-project/one-paper/master/DREP%20WhitePaper_KR.pdf"
    },]
    const rightList=[ {
        title: "Cross-chain Technology",
        address: "https://medium.com/drep-family/cross-chains-a-bridge-connecting-reputation-value-in-silo-b65729cb9cd9",

    },
    {
        title: 'Decentralized ID',
        address: 'https://medium.com/drep-family/decentralized-identity-ushering-in-an-era-of-transferrable-ids-d87ff5c98'
    }]
   

    const title = [{
        title: t('resoure.701'),
        subtitle: 'WHITE PAPER'
    }, {
        title: t('resoure.705'),
        subtitle: t('resource.702')
    },
    ]
    const archives=[
        {
        title: t('resoure.703'),
        subtitle:t('resource.704'),
        subject:'Media Kit Package V1.0',
        address:'https://raw.githubusercontent.com/drep-project/one-paper/master/logo.zip'
    }
    ]
    return (
        <div>
            <Nav />
            <div className="p-resource">
               
                <div className="content-wrapper">
                    <div className="page-title">
                        {t('resource.701')}
                    </div>
                    <div className="page-content">
                        <div className='main-title'>
                            <span>{t('rescource.705')}</span>
                            <div className="line"></div>
                        </div>
                        <div className="sub-title-container">
                            {
                                title.map((item, index) =>
                                    
                                    <span key={index} className='sub-title'>{item.subtitle}</span>
                                )
                            }
                        </div>
                        <div className="content-box">
                            <div className='content'>
                                {
                                    linkList.map((item) =>
                                        <a key={uuidv4()} href={item.address}>{item.title}</a>
                                    )
                                }
                            </div>
                            <div className="content-right">
                                {
                                    rightList.map((item)=>
                                    <a href={item.address}   key={uuidv4()}>{item.title}</a>
                                    )
                                }
                            </div>
                        </div>
                        <div className='main-title'>
                            <span>{t('rescource.703')}</span>
                            <div className="line"></div>
                        </div>
                        <div className="sub-title-container">
                            <div className='sub-title'>{t('rescource.704')}</div>
                    
                        </div>
                        <div className='content'><a href={archives[0].address}>{archives[0].subject} </a> </div>
                    </div>
                </div>
               
            </div>
            <Footer contentshow={false} />

        </div>
    )
}
export default Resource