import './index.scss'
import FooterNav from './app/footerNav'
import Nav from '../home/app/nav'
const Footer = (props) => {

    const getstyle = () => {
        let style = []
        if(props.dark){
            style.push('dark')
        }
        if(props.transparent){
            style.push('transparent')
        }
        return style.join(' ')
    }
    // console.log("!!!!!!!!!",props.contentshow)
    return (
        <div className={`p-footer flex column justify-between h100 ${props.contentshow!==false ? 'dark' : ''}`}>
            {props.contentshow!==false&&
              <>
              <Nav dark/>
                <img className="footer-img" src={require("@/style/images/drep_img/last.png")} />
                <FooterNav dark={getstyle()} ></FooterNav>
              </>
            }
            <div className={`text-center py-7 ${getstyle()}`} style={{paddingTop:'12px'}}>
                © 2024 DREP Foundation Ltd., All Rights Reserved
            </div>
        </div>
    )
}

export default Footer