import React from 'react'
import './footerNav.scss'
import Linecharts from './lineChart';
import { useState } from 'react';
import Decimal from 'decimal.js';
import ApiServe from '@/service/handleServe'
import { useEffect } from 'react';
import BinanceIcon from '../../../style/images/home/icon-binance.png'
import GateIcon from '../../../style/images/home/icon-gate.png'
import AcsendEXIcon from '../../../style/images/home/icon-AcsendEX.png'
import CoinbaseIcon from '../../../style/images/home/icon-Coinbase.png'
import { useTranslation } from 'react-i18next';

function withZero(n) {
  if (n < 10) return `0${n}`
  return n
}
export default function FooterNav(props) {
  const { t } = useTranslation()
  const [leftList, setleftList] = useState([
    {
      icon: BinanceIcon,
      text: 'Binance',
      href: 'https://www.binance.com/en/trade/DREP_USDT?theme=dark&type=spot'
    },
    'gap',
    // {
    //   icon: CoinbaseIcon,
    //   text: 'Coinbase',
    //   href: 'https://www.coinbase.com/price/drep-new'
    // },
    // 'gap',
    {
      icon: GateIcon,
      text: 'Gate.io',
      href: 'https://www.gate.io/trade/DREP_USDT'
    },
    // 'gap',
    // {
    //   icon:AcsendEXIcon,
    //   text:'AscendEX',
    //   href:'https://ascendex.com/en/cashtrade-spottrading/usdt/drep'
    // },

  ])
  const [rightLists, setrightLists] = useState([
    {
      title: 'About us',
      list: [
        {
          text: 'Privacy policy',
          href: 'https://www.drep.org/privacy'
        },
        {
          text: 'Terms of use',
          href: 'https://github.com/drep-project/one-paper/blob/master/WEBSITE%20TERMS%20OF%20USE.pdf'
        },
        {
          text: 'Contact us',
          href: 'mailto:pr@drep.org'
        },
      ]
    },
  ])
  const [kLines, setkLines] = useState(false)
  const [latest, setlatest] = useState(false)

  async function getKLines() {
    const kline = await ApiServe.requestUrl('https://data-api.binance.vision', '/api/v3/klines', { symbol: 'DREPUSDT', interval: '1h', limit: '24' }, 'get')
    const ticker = await ApiServe.requestUrl('https://data-api.binance.vision', '/api/v3/ticker/24hr', { symbol: 'DREPUSDT' }, 'get')
    let x = kline.map(item => {
      let date = new Date(item[0])
      let years = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()

      return `${years}/${withZero(month)}/${withZero(day)} ${withZero(hour)}: 00 `
    })
    let y = kline.map(item => item[4])
    setkLines({ x, y })

    setlatest(ticker)
  }

  useEffect(() => {
    getKLines()

    return () => {
    }
  }, [])

  return <div className={`footer-container ${props.dark}`}>
    <div className={`footer-content`}>
      <div className="left">
        <div className='left-list'>
          <p>Find on</p>
          {leftList.map(item => item == 'gap' ? <div className='gap' /> : <a target="_blank" href={item.href} key={item.text}><img src={item.icon} /> {item.text}</a>)}
        </div>
        <div className="left-Info">
          <div className="bar">
            {/* href={leftList.find(item=>item.text==='Binance').href} */}
            <a href="#" className="logo">
              <img src="/images/icon-drep.png" alt="" />
              <div>
                <p>Drep</p>
                {/* <div className="powered">Powered by Binance</div> */}
              </div>
            </a>
            {kLines && latest.priceChangePercent ? <Linecharts data={kLines} latest={latest} color={Decimal(latest.priceChangePercent).gte(0) ? 'green' : 'red'} /> : <Linecharts data={kLines} latest={latest} color={'green'} />}
          </div>
        </div>
      </div>
      <div className="right">
        {rightLists.map(list => {
          return <div className="right-list" key={list.title}>
            <p>{list.title}</p>
            {list.list.map(item => <a key={item.text} href={item.href}>{item.text}</a>)}
          </div>
        })}
      </div>

    </div>
    <div className="icon_box l-hide m-hide flex mt-5 justify-center">
        <a href="https://t.me/drep_foundation" target="_blank">
          <div className="telegram"></div>
        </a>
        <a href="https://drep-foundation.medium.com/" target="_blank">
          <div className="medium"></div>
        </a>
        <a href="https://twitter.com/DrepOfficial" target="_blank">
          <div className="twitter"></div>
        </a>
      </div>
  </div>
}
