/*
 * @Author: jack-ouyang
 * @Email: jiawangouyang@pawnfi.com
 * @Date: 2022-01-24 16:55:44
 * @Last Modified by: jack-ouyang
 * @Last Modified time: 2022-02-14 11:24:27
 * @Description: Description
 */

import { useNavigate } from 'react-router-dom'
import Nav from '@/pages/home/app/nav'
import './index.scss'

const HeaderBack = () => {
    const history = useNavigate()
    const back = () => {
        history(-1)
    }
    return (

        <div className="components-header-back bg-2">
            <Nav line />
            <div className="header-back-box">
                <span className="iconfont icon-icon-return is_4 cursor" onClick={back}></span>
            </div>
        </div>
    )
}

export default HeaderBack