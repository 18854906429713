import React from 'react'
import ApiServe from '@/service/handleServe'
import { useState } from 'react'
export default function useBeforeRouter() {
  const [flag, setflag] = useState(true)
  ApiServe.http('get_ipcountryinfo',{}).then(res=>{
    // console.log(res);
    if(res.country_code==='CN')setflag(false)
    else setflag(true)
  })
  
  
	return flag
}

