
import { useNavigate } from 'react-router-dom';
import NavBtn from './navBtn'
import './nav.scss'
import { useTranslation } from "react-i18next"
import { Menu, Dropdown, Button, Space } from 'antd';

const NavHeader = () => {
    const { t } = useTranslation()
    const navList = [
        // {name:t('nav.101'),link:'https://swap.drep.org/',router: '',type: 2},
        // {name:t('nav.102'),link:'https://github.com/drep-project/DREP-Chain',router: '',type: 2},
        // {name:t('nav.103'),link:'http://docs.drep.org',router: '',type: 2},
        
        // {name:t('nav.104'),link:'https://etherscan.io/address/0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2',router: '',type: 105},
        // {name:t('nav.106'),link:'',router: '',type: 106},
        {name: 'NFT', link: '', router: '', type: 108},
        {name: 'Staking',link: 'https://www.lookfor.io/lfg', router: '', type: 2},
        {name:'Chain',link:'',router: '',type: 109},
        {name:t('Community'),link:'',router: '',type: 107},
    ]
    const history = useNavigate()
    
    
    const mtMouseOver = (e) => {
        //  悬停动画
        const _target = e.target
        _target.style.animation = 'wordAnimation 380ms ease-in-out forwards'

        _target.addEventListener('animationend', () => {
            _target.style.animation = null
        })
    }
    const mtTobtn = (item) => {
        switch (item.type) {
            case 1:
                history(item.router)
                break;
            case 2:
                window.open(item.link)
                break;
            default:
                break;
        }
    }

    const exchangeMenu = (
        <Menu className='exchange-menu menu'>
             <Menu.Item key={'0'}>
               <span className='pa-5 inline-block fs-2 text-8b8b91'>
                   Buy $DREP
               </span>
            </Menu.Item>
            <Menu.Item key={'1'}>
                <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://www.binance.com/en/trade/DREP_USDT?theme=dark&type=spot">
                    <img src={require("@/style/images/home/icon-binance.png")} className='mr-4' width={32} alt="" />
                    <div>
                        <p className=' fs-2 fw-b'>Binance</p>
                        <p className='text_secondary'>{t('nav.106-drop.binace')}</p>
                        </div>
                </a>
            </Menu.Item>
            {/* <Menu.Item key={'2'}>
                <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://www.coinbase.com/price/drep-new">
                    <img src={require("@/style/images/home/icon-Coinbase.png")} className='mr-4' width={32} alt="" />
                    <div>
                        <p className=' fs-2 fw-b'>Coinbase</p>
                        <p className='text_secondary'>{t('nav.106-drop.coinbase')}</p>
                        </div>
                </a>
            </Menu.Item> */}
            <Menu.Item key={'2'}>
                <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://www.gate.io/trade/DREP_USDT">
                    <img src={require("@/style/images/home/icon-gate.png")} className='mr-4' width={32} alt="" />
                    <div>
                        <p className=' fs-2 fw-b'>Gate.io</p>
                        <p className='text_secondary'>{t('nav.106-drop.gate')}</p>
                        </div>
                </a>
            </Menu.Item>
            {/* <Menu.Item key={'4'}>
                <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://ascendex.com/en/cashtrade-spottrading/usdt/drep">
                    <img src={require("@/style/images/home/icon-AcsendEX.png")} className='mr-4' width={32} alt="" />
                    <div>
                        <p className=' fs-2 fw-b'>AscendEX</p>
                        <p className='text_secondary'>{t('nav.106-drop.acsend')}</p>
                        </div>
                </a>
            </Menu.Item> */}
        </Menu>
    )

    const nftMenu = (
      <Menu className='exchange-menu menu'>
           <Menu.Item key={'0'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://blur.io/collection/lookfor-gem">
                    <svg className="icon is_5 mr-4" aria-hidden="true">
                      <use xlinkHref={`#icon-icon-Blur1`}></use>
                    </svg>
                    <div>
                        <p className=' fs-2 fw-b'>Trade LookFor Gem</p>
                        </div>
              </a>
          </Menu.Item>
      </Menu>
  )

    const tokenMenu = (
        <Menu className='exchange-menu menu small'>
             <Menu.Item key={'0'}>
               <span className='pa-5 inline-block fs-2 text-8b8b91'>
                   Chain
               </span>
            </Menu.Item>
            <Menu.Item key={'1'}>
                <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://etherscan.io/token/0x3ab6ed69ef663bd986ee59205ccad8a20f98b4c2">
                    <img src={require("@/style/images/home/icon_eth.png")} className='mr-4' width={32} alt="" />
                    <div>
                        <p className=' fs-2 fw-b'>ETH</p>
                        {/* <p className='text_secondary'>{t('nav.106-drop.binace')}</p> */}
                        </div>
                </a>
            </Menu.Item>
            <Menu.Item key={'2'}>
                <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://bscscan.com/token/0xec583f25a049cc145da9a256cdbe9b6201a705ff">
                    <img src={require("@/style/images/home/icon-binance.png")} className='mr-4' width={32} alt="" />
                    <div>
                        <p className=' fs-2 fw-b'>BSC</p>
                        {/* <p className='text_secondary'>{t('nav.106-drop.coinbase')}</p> */}
                        </div>
                </a>
            </Menu.Item>
        </Menu>
    )

    const ChainMenu = (
      <Menu className='exchange-menu menu small'>
          <Menu.Item key={'1'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://explore-testnet.drep.org/">
                  <div>
                      <p className=' fs-2 fw-b'>Drepscan</p>
                      {/* <p className='text_secondary'>{t('nav.106-drop.binace')}</p> */}
                      </div>
              </a>
          </Menu.Item>
          <Menu.Item key={'2'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://faucet-testnet.drep.org/">
                  <div>
                      <p className=' fs-2 fw-b'>Faucet</p>
                      {/* <p className='text_secondary'>{t('nav.106-drop.coinbase')}</p> */}
                      </div>
              </a>
          </Menu.Item>
      </Menu>
  )

    const communityMenu = (
      <Menu className='exchange-menu menu small'>
           <Menu.Item key={'0'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://twitter.com/DrepOfficial">
                  {/* <img src={require("@/style/images/home/icon_eth.png")} className='mr-4' width={32} alt="" /> */}
                  <div>
                      <p className=' fs-2 fw-b'>Twitter</p>
                      {/* <p className='text_secondary'>{t('nav.106-drop.binace')}</p> */}
                  </div>
              </a>
          </Menu.Item>
          <Menu.Item key={'1'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href=" https://t.me/drep_foundation">
                  {/* <img src={require("@/style/images/home/icon_eth.png")} className='mr-4' width={32} alt="" /> */}
                  <div>
                      <p className=' fs-2 fw-b'>Telegram</p>
                      {/* <p className='text_secondary'>{t('nav.106-drop.binace')}</p> */}
                      </div>
              </a>
          </Menu.Item>
          <Menu.Item key={'2'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://discord.com/invite/lookfor">
                  {/* <img src={require("@/style/images/home/icon-binance.png")} className='mr-4' width={32} alt="" /> */}
                  <div>
                      <p className=' fs-2 fw-b'>LookFor Discord</p>
                      {/* <p className='text_secondary'>{t('nav.106-drop.coinbase')}</p> */}
                      </div>
              </a>
          </Menu.Item>
          <Menu.Item key={'3'}>
              <a target="_blank" rel="noopener noreferrer" className='flex align-center pa-5' href="https://drep-foundation.medium.com/">
                  {/* <img src={require("@/style/images/home/icon-binance.png")} className='mr-4' width={32} alt="" /> */}
                  <div>
                      <p className=' fs-2 fw-b'>Medium</p>
                      {/* <p className='text_secondary'>{t('nav.106-drop.coinbase')}</p> */}
                      </div>
              </a>
          </Menu.Item>
      </Menu>
  )

    return (
        <div className="nav-header flex justify-between align-center w100">
            <img src={require("@/style/images/home/homepage_logo.png")} alt="" height="32"/>
            <div className="flex align-center">
                <div className="s-hide">
                    {
                        navList.map((item, index) => {
                            const dom = <span className="fs-3 white_text mr-7 cursor" onClick={()=>mtTobtn(item)} onMouseOver={mtMouseOver} key={index}>{item.name}</span>
                            if(item.type === 105){
                                return <Dropdown overlay={tokenMenu} trigger={['hover']} placement="bottomLeft">{dom}</Dropdown>
                            }
                            if(item.type === 106){
                                return <Dropdown overlay={exchangeMenu} trigger={['hover']} placement="bottomLeft">{dom}</Dropdown>
                            }
                            if(item.type === 107){
                              return <Dropdown overlay={communityMenu} trigger={['hover']} placement="bottomLeft">{dom}</Dropdown>
                            }
                            if(item.type === 108){
                              return <Dropdown overlay={nftMenu} trigger={['hover']} placement="bottomLeft">{dom}</Dropdown>
                            }
                            if(item.type === 109){
                              return <Dropdown overlay={ChainMenu} trigger={['hover']} placement="bottomLeft">{dom}</Dropdown>
                            }
                            
                            return dom
                        }
                        )
                    }
                </div>
                <NavBtn dark/>

            </div>
        </div>
    )
}

export default NavHeader