import { useTranslation } from "react-i18next"
import NavHeader from '../app/navHeader'

import { useRef, useEffect } from "react"
import './index.scss'
const BannerIndex = () =>{

    const { t } = useTranslation()
    return (
        <div className="p-banner overflow-hidden pa-7">
            <img className="player l-hide" src={require("@/style/images/home/video.gif")} alt="" />
            <video className="player s-hide" muted="muted" autoPlay loop="loop" src="https://video.wixstatic.com/video/0492f5_74c76cd2d3fe4c3185b7376d9718fb99/720p/mp4/file.mp4" type="video/mp4">
            </video>
            <NavHeader />
            <img className="footer-img" src={require("@/style/images/drep_img/Home.png")} alt="" width="12"/>
            <div className="flex column title">
                <div className=" mb-4">
                        <div className="text-center title-name white_text"><div className="title-logo" />$DREP Staking in Full Swing!</div>
                        <div className="title_gap"></div>
                        <div className="flex justify-center align-center line-name">
                            <span className="white_text ">Stake $DREP with LookFor GEM to Earn Up to 200% APY</span>
                        </div>
                        <div className="flex justify-center mt-7">
                           <div className="px-7 py-4 br-5 title-btn cursor" onClick={() => window.open('https://www.lookfor.io/lfg')}>
                            Stake Now
                             {/* <div className="fs-2 text-center">Earn DREP</div> */}
                           </div>
                        </div>
                </div>
                {/* <div className="flex justify-center">
                    <a href="https://swap.drep.org/" className="cursor btn py-4 px-8 fs-3 text_primary">{t('nav.101')}</a>
                </div> */}
                
            </div>
        </div>
    )
}

export default BannerIndex
