
import './index.scss'
import BannerIndex from './banner/index'
import DrepPage from './drep/index'
import Solutions from './solutions/index'
import Roadmap from './roadmap/index'
import NetWork from './network/index'

import { useEffect, useState } from 'react'
import Case from './case'
import Footer from '../footer/index'
import Nav from './app/nav'

const Swiper = window.Swiper
const HomeIndex = () => {
    const [mySwiper, setMySwiper] = useState({})
    const [swiperIndex, setSwiperIndex] = useState(0)
    useEffect(() => {
        console.log('12121')
        let newSwiper = new Swiper('.swiper-home', {
            speed: 1200,
            // allowTouchMove: false,
            lazy: {
                // loadPrevNext: true,
                // loadPrevNextAmount: 2
            },
            direction: 'vertical',
            threshold: 50,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable :true,
            },
            mousewheel: true,
            on: {
                slideChangeTransitionStart: () => {
                    console.log('mySwiper.realIndex=',newSwiper.realIndex)
                    setSwiperIndex(newSwiper.realIndex)
                //   this.nowIndex = this.mySwiper.realIndex
                }
              }
        })
        setMySwiper(newSwiper)
    },[])
    const mtClick = () => {
        console.log('mySwiper.activeIndex=',mySwiper)
        Object.keys(mySwiper).length > 0 && mySwiper.slideTo(6);
    }
    return (
        <div className="p-home">
            <div className="swiper-container swiper-home overflow_hidden align" style={{height: '100vh',width:'100vw'}}>
                <div className="swiper-wrapper ">
                    <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}><BannerIndex /></div>
                    <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}><DrepPage swiperIndex={swiperIndex} /></div>
                    {/* <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}><Solutions swiperIndex={swiperIndex} /></div> */}
                    <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}><Case swiperIndex={swiperIndex} /></div>
                    <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}><Roadmap swiperIndex={swiperIndex} /></div>
                    <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}><NetWork /></div>
                    <div className="swiper-slide" style={{height: '100vh',width:'100vw'}}>
                      <Footer transparent/>  
                    </div>
                    
                    
                </div>
                <div className="swiper-pagination"></div>

                <span onClick={mtClick} className={`swiper-button-next my-swiper-button-next iconfont icon-icon-more ${swiperIndex === 6 ? 'hide' : ''} ${swiperIndex === 0 || swiperIndex === 4 || swiperIndex === 5 || (swiperIndex === 3 && window.screen.width < 768) ? 'swiper-button-white' : 'swiper-button-black'}`}>
                </span>
            </div>
            
        </div>
    )
}

export default HomeIndex