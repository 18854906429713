import { useState } from "react"
import { useEffect } from "react"
import Nav from "../app/nav"
import PagingList from '../app/pagingList'
import { useTranslation } from "react-i18next"
import './index.scss'
import drep_client  from '@/style/images/home/drep_client.png'
import drep_id   from '@/style/images/home/drep_id.png'
import drep_sdk from "@/style/images/home/drep_sdk.png"
import drep_client1  from '@/style/images/home/drep_client (1).png'

const Swiper = window.Swiper
const Solutions = (props) =>{
    const {t}=useTranslation()
    const { swiperIndex } = props
    const [indexNew, setIndexNew] = useState(0)
    const data = [
        {
            url:drep_client,
            title: 'USE CASE',
            subtitle: t('solutions.401'),
            subtitle1: '',
            lable: 'DREP Chain'
        },
        {
            url: drep_id ,
            title: 'USE CASE',
            subtitle: t('solutions.402'),
            subtitle1: '',
            lable: 'DREP ID'
        },
        {
            url: drep_sdk,
            title: 'USE CASE',
            subtitle: t('solutions.403'),
            subtitle1: '',
            lable: 'DREP SDK'
        },
        {
            url: drep_client1,
            title: 'USE CASE',
            subtitle: t('solutions.405'),
            subtitle1: '',
            lable: 'DREP CLIENT'
        },
    ]
    const change = (index) => {
        console.log(index,'index=', typeof index)
        setIndexNew(index)
    }
    return (
        <div className="p-solutions-page p-relative h100">
            <Nav />
            <div className="footer-img">
                <img src={require("@/style/images/drep_img/usercase.png")} alt="" width="13" />
            </div>

            <div className="solutions-center-box">
                <div className="solutions-center">
                    <div className="solutions-title">
                        <div className="container-title">
                            <div className={`title-num fw-b ${swiperIndex === 2? 'animation-name' : 'transform'} `}>02</div>
                            <div className={`title fw-b mt-4 ${swiperIndex === 2 ? 'animation-name' : 'transform'}`}>{data[indexNew].title}</div>
                            <div className="ani-title-container mt-5 ">
                                <div className={`ani-bottom-division ${swiperIndex === 2 ? 'animation-name' : 'transform'} `}></div>
                            </div>
                            <div className={`mt-6 fs-2 ${swiperIndex === 2 ? 'animation-name' : 'transform'} `}>
                                {data[indexNew].subtitle}
                            </div>
                        </div>
                        <div className="raginglist">
                            <PagingList list={data} indexNew={indexNew} onChange={change}/>
                        </div>
                    </div>
                    <div className="img-box">
                        <img src={data[indexNew].url} alt="" />
                        <span className="cursor">{data[indexNew].title}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Solutions