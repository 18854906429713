import { useLocation, useRoutes } from "react-router-dom";
import HomeIndex from '../pages/home';
import Privacy from '../pages/privacy'

import Media from '../pages/media'
import Resource from "../pages/resource-center";
import MediaList from "../pages/media/list/list";
import MediaDetaile from "../pages/media/list/detaile";
import { useEffect } from "react";
const RouterBefore = () => {
    const locat = useLocation();
    useEffect(() => {
        // window.scrollTo(0, 0)
    },[locat])
    const RouterList = useRoutes([
        {
            path: '/',
            element: <HomeIndex />,
        },
        {
            path: '/resource',
            element: <Resource />,
        },
        {
            path: '/privacy',
            element: <Privacy />,
        },
        {
            path: '/media',
            children: [
                {
                    index: true,
                    element: < Media/>
                },
                {
                    path: 'list',
                    element: <MediaList />
                },
                {
                    path: 'detaile',
                    element: <MediaDetaile />
                }
            ]
        }
    ])
    return RouterList
}

export default RouterBefore
