
import { useEffect, Fragment } from 'react'
import Nav from '../home/app/nav'
import Footer from '../footer/index'
import NewItem from './app/newItem/index'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import ApiServe from '../../service/handleServe'
import { useState } from 'react'
import { getLangObject } from '../../i18n/langUtil'
const Swiper = window.Swiper
const Media = () => {
    const history = useNavigate()
    const [data, setData] = useState([])

    const newsList = [{
        "title": "DREP智能合约升级公告",
        "image": require("@/style/images/drep_img/news004.png"),
        "time": "2019 / 09 / 11",
        "des": "官方考虑到所有DREP持币用户接收信息和兑换DREP的时间周期，我们计划于2019年9月16日12:00(UTC+8)完成本次合约升级。",
        "content": "尊敬的DREP用户：<p>DREP智能合约升级已从2019年9月6日正式开始，考虑到所有DREP持币用户接收信息和兑换DREP的时间周期，我们计划于2019年9月16日12:00(UTC+8)完成本次合约升级。<p>届时DREP已正式上线的交易平台将恢复DREP充提，所有DREP持币用户可正常进行新DREP的交易。<p>如有存储于去中心化钱包的旧DREP，未在2019年9月16日12:00(UTC+8)前与DREP官方完成兑换，仍可以联系<a href=\"mailto:swap@drep.org?subject=合约升级\"><strong>swap@drep.org</strong></a>。DREP SWAP小组会在核实相关情况后，每个月为申请用户集中进行一次兑换。<p>DREP基金会<br>2019年9月11日",
        "url": "/news/0"
      },
      {
        "title": "DREP智能合约升级公告",
        "image": require("@/style/images/drep_img/news004.png"),
        "time": "2019 / 08 / 09",
        "des": "因DREP预主网<Drep Planck>即将上线，DREP团队将于2019年9月6日12:00（UTC+8）开始进行DREP智能合约升级。",
        "content": "亲爱的DREP用户：因DREP预主网<Drep Planck>即将上线，DREP团队将于2019年9月6日12: 00（UTC+8）开始进行DREP智能合约升级。新的DREP合约地址为：0x22dE9912cd3D74953B1cd1F250B825133cC2C1b3。届时DREP持币用户需配合完成以下操作：1. 新的DREP智能合约升级完成后，旧合约DREP代币将失效。具体升级时间参见各交易所公告。2. 如果DREP新合约升级期间，您的旧DREP代币存储于官方已经上线的交易所中，新合约DREP代币将由交易所代为映射，您无需做任何操作，即可获得新合约DREP代币。 3. 如果您的旧DREP代币存放于ERC20钱包中，可于新合约升级完成后联系DREP官方客服“DREP可爱多”或“DREP娜娜”进行人工新合约DREP代币兑换。DREP可爱多微信号：DREP_FoundationDREP娜娜微信号：DREP_service  4. 现已上线DREP的交易所将关闭DREP充提功能，在完成新合约升级后，各大交易所将陆续开启充提币功能，具体情况请等待交易所正>式公告。",
        "url": "/news/1"
      },
      {
        "title": "DREP合约升级 - 兑换指南",
        "image": require("@/style/images/drep_img/news004.png"),
        "des": "因DREP预主网<DREP Planck>即将上线，DREP的智能合约升级工作将于2019年9月6日正式开始，新的合约地址为：0x22dE9912cd3D74953B1cd1F250B825133cC2C1b3 ...",
        "url": "https://mp.weixin.qq.com/s/BDP6eLSC61wl75aU-nKxWQ"
      },
      {
        "title": "DREP早期私募份额将推迟于10月中旬解锁，未来2个月内不会产生新增流通量",
        "image": require("@/style/images/drep_img/news004.png"),
        "des": "为维护DREP长期良性发展，并提振投资者信心，DREP基金会与早期私募投资者一致同意，修改原白皮书中“每2个月释放1次”的私募解锁计划 ...",
        "url": "/news/2",
        "time": "2019 / 08 / 09",
        "content": "为维护DREP长期良性发展，并提振投资者信心，DREP基金会与早期私募投资者一致同意，修改原白皮书中“每2个月释放1次”的私募解锁计划。原计划于2019年8月底、10月底、12月底分别释放的DREP>，将合并为同一批，并于2019年10月10日解锁，届时DREP将与多方合作展开Staking及节点计划。\n\n除未来2个月内不会产生任何新增流通量以外，DREP基金会还宣布：DREP基金会份额、DREP团队份额、DREP顾问份额自愿锁仓1年，将于2020年5月起，分3年梯度解锁。DREP目前已上线Gate.io、BitMax、Binance DEX等交易平台，未来数月内将陆续公布更多重磅合作伙伴及新上线交易平台。"
      }
    ]

    const [banner,setBanner] = useState([
        'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/media-center-banner.png',
    ])

    const [lang, setLang] = useState('zh_cn')

    useEffect(()=>{
        new Swiper('.swiper1', {
            observer:true,//修改swiper自己或子元素时，自动初始化swiper
            // observeParents:true,//修改swiper的父元素时，自动初始化swiper
            loop: true,
            // loopAdditionalSlides: 0,
            autoplay: {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
            },
        })
        getLang()
        getSelectnewInfo()
    }, [])

    const getLang = () => {
        setLang(getLangObject().value === 'cn' ? 'zh_cn' : 'en')
    }
    const getSelectnewInfo = () => {
        ApiServe.http('GET_SELECTNEW_INFO',{
            page_size :4,
            page: 1
        }).then(res => {
            console.log('res==',res)
            setData(res.data)
        }).catch(err => {
            console.log('err==',err)
        })
    }
    const toDetaile = (item) => {
        console.log('item,',item)
        if(item.f_article_type === '1'){
            window.open(item['f_link_url_' + lang])
        }else{
            history('/media/detaile', {state: item})
        }
        
    }
    const toList = () => {
        history('/media/list')
    }
    return (
        <div className="p-media bg-2">

            <div className="media-header">
                <Nav dark />
                <div className="swiper-container swiper1">
                    <div className="swiper-wrapper ">
                        {
                            banner.map((item, index) =>  
                                <div className="swiper-slide" key={index}>
                                        <img src={item} alt=""/>
                                </div>
                            )
                        }
                    
                    </div>
                </div>
            </div>
            <div className="px-7">
                <div className="text-center mb-7">
                    <img src={require("@/style/images/media/news_title.png")} alt="" className="title-img" />
                </div>
                <div className="news-list flex flex-warp align-center mb-4">
                    {
                        data.map((item, index) =>
                            <Fragment key={index} >
                                <NewItem data={item} lang={lang} index={index} click={() => toDetaile(item)} />
                            </Fragment>
                        )
                    }
                </div>
                <div className="flex justify-end">
                    {/* <div className="news-more" onClick={toList}>More</div> */}
                </div>

                <div className="text-center mb-7">
                    <img src={require("@/style/images/media/video_title.png")} alt="" className="title-img" />
                </div>
                <MediaVideo />

                <div className="text-center my-7">
                    <img src={require("@/style/images/media/media_title.png")} alt="" className="title-img" />
                </div>
                <Coverage />

                
            </div>
            <Footer dark/>
        </div>
    )
}

const MediaVideo = () => {
    const videoArr= [
        { url: 'https://www.youtube.com/watch?v=_PmxGqu07G8', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/7.png' },
        { url: 'https://www.youtube.com/watch?v=-R-3-0OocmU', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/5.png' },
        { url: 'https://www.youtube.com/watch?v=qtK-IcR4umc', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/6.png' },
        { url: 'https://www.youtube.com/watch?v=hOQmYbfModM', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/1.png' },
        { url: 'https://www.youtube.com/watch?v=OL1yRpkiHME', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/2.png' },
        { url: 'https://www.youtube.com/watch?v=dqjK18cX6TU', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/3.png' },
        { url: 'https://www.youtube.com/watch?v=QWSd-uIWv_M', poster: 'https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/media/4.png' }
    ]
    const videoState = ['start','start','start','start','start','start','start']
    useEffect(() => {
        if(Swiper){
            console.log('12131')
            new Swiper('.swiper2', {
                    loop: true,
                    loopAdditionalSlides: 0,
                    // loopedSlides: 2,
                    slideToClickedSlide: true,
                    notNextTick: true,
                    watchSlidesProgress: true,
                    // loadPrevNext: true,
                    // loadPrevNextAmount: 1,
                    // lazy: true,
                    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true,
                    effect: 'coverflow',
                    slidesPerView: 1.5,
                    centeredSlides: true,
                    threshold: 30,
                    coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 300,
                    modifier: 3,
                    slideShadows: true
                },
            })
        }
    }, [])
    const goUrl = (url) => {
        window.open(url)
    }
    // Swiper
    return(
        <div className="swiper-container swiper2">
            <div className="swiper-wrapper">
                {
                    videoArr.map((item, index) => 
                        <div className="swiper-slide" key={index}>
                            {
                                videoState[index] === 'start' &&
                                    <div className="shelter">
                                        <span onClick={()=>{goUrl(item.url)}}>{ videoState[index] }</span>
                                    </div>
                            }
                            <video
                                src={item.url}
                                poster={item.poster}
                            />
                        </div>
                    )
                }
               
            </div>
        </div>
    )
}

const Coverage = () => {
    const mediaCoverage = [
        // {
        //   name: "YahooFinance",
        //   link: "https://finance.yahoo.com/news/drep-foundation-strengthening-depth-exchange-131200624.html",
        //   imageUrl: "/images/media/y.png"
        // }
        {
          name: "Forbes",
          link: "https://www.forbes.com/",
          imageUrl: require('@/style/images/home/f.png')
        }, {
          name: "BTCManager",
          link: "https://btcmanager.com/",
          imageUrl: require('@/style/images/home/btc.png')
        }, {
          name: "CryptoSlate",
          link: "https://cryptoslate.com/",
          imageUrl: require('@/style/images/home/cr.png')
        }, {
          name: "BlockTribune",
          link: "https://blocktribune.com/",
          imageUrl: require('@/style/images/home/bt.png')
        }, {
          name: "Sohu",
          link: "http://www.sohu.com/",
          imageUrl: require('@/style/images/home/souhu.png')
        }, {
          name: "CryptoNinjias",
          link: "https://www.cryptoninjas.net/",
          imageUrl: require('@/style/images/home/cry.png')
        }, {
          name: "CryptoPanic",
          link: "https://cryptopanic.com/",
          imageUrl: require('@/style/images/home/cp.png')
        }, {
          name: "BexchangeG",
          link: "https://bitcoinexchangeguide.com/",
          imageUrl: require('@/style/images/home/be.png')
        }, {
          name: "BitcoinPriceMedia",
          link: "https://bitcoin.org/en/",
          imageUrl: require('@/style/images/home/bit.png')
        }
    ]
    return (
        <div className="coverage">
           {
                mediaCoverage.map((item,index) => 
                    <a href={item.link} className="coverage-url" target="_blank" key={index}><img src={item.imageUrl} alt="" /></a>
                )
           }
        </div>
    )
}

export default Media