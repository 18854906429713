import { useState } from 'react'
import Nav from '../app/nav'
import { useTranslation } from "react-i18next"

import './index.scss'

const Roadmap = () => {
    const { t } = useTranslation()
    
    const [activeIdx, setActiveIdx] = useState(1)
    const [activeIdxmb, setActiveIdxmb] = useState(1)
    const [pointLeft, setPointLeft] = useState('calc(' + '10% '+ ' - 72px )')
    const selectStyle = (left, activeIdx) => {
        setActiveIdx(activeIdx)
        setPointLeft(left)
    }
    const homeRoadmap = [{
        "title": "发布第一个DREP客户端测试版本",
        "title1": "完成主网的资产映射，实现DREP链内部的资产结算",
        "title2": "上线DREP测试网络3.0 无穷之道-Euler",
        "title3": "推出DREP游戏SDK Alpha版本，实现多资产集成和代币Swap功能"
      },
      {
        "title": "发布第一个DREP客户端测试版本",
        "title1": "改善代码风格、面向Service的编程，类似于Java的Spring容器开发，让各个模块充分解耦，代码可以轻松重构",
        "title2": "开发更多针对企业用途的声誉协议插件和API",
        "title3": "搭建用户声誉流量池中的用户肖像和定向筛选机制",
        "title4": "DREP主网测试阶段",
        "title5": "白皮书V2.0.2版本更新"
      },
      {
        "title": "DREP主网1.0上线",
        "title1": "DREP 多端上线",
        "title2": "DREP ID v2.0 开发",
        "title3": "DREP DID 2.0 & SDK上线"
      },
      {
        "title": "DREP声誉系统上线",
        "title1": "DREP零知识证明一体化"
      }
    ]
    return (
        <div className="p-roadmap h100">
            <div className="s-hide">
                <Nav />
            </div>
            <div className="l-hide">
                <Nav dark/>
            </div>
            <img className="footer-img s-hide" src={require("@/style/images/drep_img/networks.png")} />
            <div className="roadmap-centent px-7 h100">
                <div className="l-hide">
                    <img src={require("@/style/images/home/roadmap.png")} alt="" width="100px" />
                </div>
                <ul className="time-wraper s-hide">
                    <li className="time-item odd time-item0" onMouseOver={()=>{selectStyle("calc(10% - 72px)",1)}}>
                        <div className={` item-content ${activeIdx===1 ? 'is-active' : ''}`}>
                            <h3>2024 Q1</h3>
                            <p>. {t('roadmap.601') }<br />
                                . {t('roadmap.602')}<br />. {t('roadmap.603')}
                                <br />. {t('roadmap.604')}
                                <br />. {t('roadmap.617')}
                            </p>
                        </div>
                    </li>

                    <li className="time-item even time-item1" onMouseOver={()=>{selectStyle("calc(30% - 65px)",2)}}>
                        <div className={` item-content ${activeIdx===2 ? 'is-active' : ''}`}>
                            <p>
                            . { t('roadmap.605')} <br/> . {t('roadmap.606')}
                                <br/> . {t('roadmap.607')}<br/> . {t('roadmap.618')}<br/> . {t('roadmap.619')}
                            </p>
                            <h3 >2024 Q2</h3>
                        </div>
                    </li>

                    <li className="time-item odd time-item2" onMouseOver={()=>{selectStyle("calc(50% - 58px)",3)}}>
                        <div className={` item-content ${activeIdx===3 ? 'is-active' : ''}`}>
                        <h3>2024 Q3</h3>
                        <p >. {t('roadmap.609')} <br /> . {t('roadmap.610')} <br/>
                        . {t('roadmap.611')} <br/> . {t("roadmap.612")}<br/> . LookFor Multi-Chain NFT Market Launch
                        <br/> . LookFor Points System Launch </p>
                        </div>
                    </li>

                    <li className="time-item even time-item3" onMouseOver={()=>{selectStyle("calc(70% - 48px)",4)}}>
                        <div className={` item-content ${activeIdx===4 ? 'is-active' : ''}`}>
                        <p >. {t('roadmap.613')} <br /> . {t('roadmap.614')} <br/>
                        . {t('roadmap.615')} <br/> . {t("roadmap.616")}<br/> . DREP Credit On-chain Testnet </p>
                        <h3>
                            2024 Q4
                        </h3>
                        </div>
                    </li>

                    <li className="circle-d" style={{left: pointLeft}}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </li>
                </ul>


                <div className="centent-mobile l-hide">
                    <div className="card-box">
                        {
                            activeIdxmb === 1 ? 
                                <div className="title">
                                    <h3>Q1</h3>
                                    <p className="centent_box mb-4">
                                        <span className="yuan">· </span>
                                        {t('roadmap.601') }<br />
                                        <span className="yuan">· </span>
                                        {t('roadmap.602')}<br />
                                        <span className="yuan">· </span>
                                        {t('roadmap.603')}<br />
                                        <span className="yuan">· </span>
                                        {t('roadmap.604')}<br />
                                        <span className="yuan">· </span>
                                        {t('roadmap.617')}
                                    </p>
                                    <h3 >Q2</h3>
                                    <p className="centent_box">
                                        <span className="yuan">· </span>
                                        { t('roadmap.605')} <br/>
                                        <span className="yuan">· </span>
                                        {t('roadmap.606')}<br/>
                                        <span className="yuan">· </span>
                                        {t('roadmap.607')}<br/> 
                                        <span className="yuan">· </span>
                                        {t('roadmap.618')}<br/> 
                                        <span className="yuan">· </span>
                                        {t('roadmap.619')}<br/> 
                                    </p>
                                </div>
                            :
                                <div className="title">
                                    <h3>Q3</h3>
                                    <p className="centent_box mb-4">
                                        <span className="yuan">· </span>
                                        {t('roadmap.609')}<br/>
                                        <span className="yuan">· </span>
                                        {t('roadmap.610')}<br/>
                                        <span className="yuan">· </span>
                                        {t('roadmap.611')}<br />
                                        <span className="yuan">· </span>
                                        {t('roadmap.612')}<br />
                                        <span className="yuan">· </span>
                                        LookFor Multi-Chain NFT Market Launch<br />
                                        <span className="yuan">· </span>
                                        LookFor Points System Launch<br />
                                    </p>
                                    <h3 >Q4</h3>
                                    <p className="centent_box">
                                        <span className="yuan">· </span>
                                        {t('roadmap.613')}<br />
                                        <span className="yuan">· </span>
                                        {t('roadmap.614')}<br/>
                                        <span className="yuan">· </span>
                                        { t('roadmap.615')}<br/>
                                        <span className="yuan">· </span>
                                        {t('roadmap.616')}<br/>
                                        <span className="yuan">· </span>
                                        DREP Credit On-chain Testnet<br />
                                    </p>
                                    
                                </div>
                        }
                    </div>

                    <div className="select flex align-center justify-between">
                        <div className="select-item" onClick={() => {setActiveIdxmb(1)}}>
                            <img className={`${activeIdxmb === 1 ? '' : 'img-active'}`} src={require("@/style/images/home/smallball.png")} alt="" />
                            <span>2024 Q1 - Q2</span>
                        </div>
                        <div className="select-item" onClick={() => {setActiveIdxmb(2)}}>
                            <img className={`${activeIdxmb === 2 ? '' : 'img-active'}`}  src={require("@/style/images/home/smallball.png")} alt="" />
                            <span>2024 Q3 - Q4</span>
                        </div>
                    </div>

                </div>

            
            </div>
        </div>
    )
}

export default Roadmap