
import { useState } from 'react'
import PagingList from '../app/pagingList'
import Nav from "../app/nav"
import { useTranslation } from 'react-i18next'
import './index.scss'

const Case = (props) => {
    const { t } = useTranslation()
    const { swiperIndex } = props
    const [swiperSub, setSwiperSub] = useState(0)
    const data = [
        {
          url: require("@/style/images/drep_img/img_frist.jpg"),
          title: 'Our Ecosystem',
          title1: 'DREP Chain', 
          subtitle: t('case.505')
        },
        {
          url: require("@/style/images/drep_img/img_yeeyi.jpg"),
          title: 'Our Ecosystem',
          title1: 'LookFor', 
          subtitle:  <div>{t('case.501')} <a target="_blank" rel="noopener noreferrer" href="https://lookfor.io/">Click here for more.</a></div>
        },
        {
            url: require("@/style/images/drep_img/img_drepmining.jpg"),
            title: 'Our Ecosystem',
            title1: 'LookFor Gem',
            subtitle: t('case.502')
        },
        {
          url: require("@/style/images/drep_img/img_harkhark.jpg"),
          title: 'Our Ecosystem',
          title1: 'DREP Credit 2.0',
          subtitle: t('case.503')
        },
    ]
    const change = (index) => {
        console.log(index, 'index=', typeof index)
        setSwiperSub(index)
    }


    return (
        <div className="p-case-page  ">
            <Nav />
            <div className="case-center-box">
                <div className="case-center">
                    {/* <div className="footer-img">
                        <img src="https://drep-resources.s3-ap-northeast-1.amazonaws.com/drep/images/usercase/solution.png" alt="" width="13" />
                    </div> */}
                    <div className="img-content">
                        <img src={data[swiperSub].url} alt="" />
                    </div>
                    <div className="content-right">
                        <div className="content-text">
                            <div className={`title-num fw-b ${swiperIndex === 2 ? 'animation-name' : 'transform'} `}>02</div>
                            <div className={`title fw-b mt-4 ${swiperIndex === 2 ? 'animation-name' : 'transform'}`}>{data[swiperSub].title}</div>
                            <div className="ani-title-container mt-5">
                                <div className={`ani-bottom-division ${swiperIndex === 2 ? 'animation-name' : 'transform'} `}></div>
                            </div>
                            <div className={`fs-3 fw-b mt-4 ${swiperIndex === 2 ? 'animation-name' : 'transform'}`}>{data[swiperSub].title1}</div>
                            <div className={`mt-6 fs-2 ${swiperIndex === 2 ? 'animation-name' : 'transform'} `}>
                                {data[swiperSub].subtitle}
                            </div>
                            <div className="raginglist">
                                <PagingList list={data} indexNew={swiperSub} onChange={change} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default Case