import i18n from "i18next"
import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from "react-i18next";
import { getLangObject } from './langUtil';
i18n.use(Backend) //嗅探当前浏览器语言
    .use(initReactI18next) //init i18next
    .init({
        //引入资源文件
        backend: {
            loadPath: `/locales/{{lng}}.json`
        },
        //选择默认语言，选择内容为上述配置中的key，即en/zh
        fallbackLng: getLangObject().value,
        debug: false,
        react: { useSuspense: false, wait: true },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
  
export default i18n;