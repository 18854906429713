import { useNavigate } from 'react-router-dom'
import NavBtn from './navBtn'

const Nav = (props) => {
    const history = useNavigate()
    const mtHistory = () => {
        history('/')
    }
    return(
        <div className={`nav-title-box flex justify-between align-center ${props.line ? 'nav-line' : ''}`}>
            {
                props.dark ? 
                    <img src={require("@/style/images/drep_img/homepage_logo.png")} onClick={mtHistory} className="cursor logo" alt="" height="32" />
                :
                    <img src={require("@/style/images/drep_img/menuLogo.png")} alt="" onClick={mtHistory} className="cursor logo" height="32"/>
            }
            
            <NavBtn dark={props.dark ? true : false}/>
        </div>
    )
}

export default Nav