import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './routers/index'


import './i18n';
import './style/public.scss'
import './style/global.scss'
import './style/iconfont.scss'
import './style/color.scss'
// import './style/webStyle.scss'
// import './style/mobileStyle.scss'
import ApiServe from './service/handleServe'

window.request = ApiServe

ReactDOM.render(
    <AppRouter />,
    document.getElementById('root')
)