
import Nav from "../app/nav"
import Footer from '../../footer/index'
import './index.scss'
const NetWork = () => {
    
    const networkList = [
        
        {link: 'https://www.binance.com/en/trade/DREP_USDT?theme=dark&type=spot', url: require("@/style/images/network/binance.png")},//1
        // {link: 'https://www.coinbase.com/price/drep-new', url: require("@/style/images/network/coinbase.png")},
        // {link: '', url: require("@/style/images/network/ccoinFire0.png")},//2  https://www.huobi.io/
        {link: 'https://www.gate.io/trade/DREP_USDT', url: require("@/style/images/network/gate.png")},//3
        {link: 'https://www.bitget.com/en-GB/spot/DREPUSDT?type=spot', url: require("@/style/images/network/bitget.png")},//4  https://www.kcs.top
        {link: 'https://www.coinw.com/frontSpot/spottrade?symbol=618', url: require("@/style/images/network/coinw.png")},//5
        {link: 'https://blur.io/collection/lookfor-gem', url: require("@/style/images/network/blur.png")},
        {link: 'https://identity.foundation/#governance', url: require("@/style/images/network/dif.png")},//6  http://inno.360.cn
        {link: 'https://coinmarketcap.com/currencies/drep-new/', url: require("@/style/images/network/coinmarketcap.png")},//7  https://qtum.org/zh
        {link: 'https://www.coingecko.com/en/coins/drep-new', url: require("@/style/images/network/coingecko.png")},//8  http://www.collinstar.com.cn/
        // {link: '', url: require("@/style/images/network/ctongzhou1.png")},//9
        // {link: '', url: require("@/style/images/network/clonglinkfund0.png")},//10

        // {link: '', url: require("@/style/images/network/img_dif.png")},//11
        // {link: '', url: require("@/style/images/network/img_landream.png")},//12  https://landream.com.au
        // {link: '', url: require("@/style/images/network/harkhark.png")},//13
        // {link: '', url: require("@/style/images/network/yeeyi.png")},//14
        // {link: '', url: require("@/style/images/network/img_blockgemini.png")},//15


        // {link: 'https://btmx.com/#/trade/usdt/drep', url: require("@/style/images/network/bitmax.png")},

    ]

    const mtToLink = (item) => {
        if(item.link !== ''){
            window.open(item.link)
        }
    }
    return (
        
        <div className="p-network">
            <Nav dark/>
            <div className="px-7">
                <div className="text-right">
                    <img src={require("@/style/images/network/network02.png")} alt="" className="network-title" />
                </div>
                <div className="flex flex-warp network-list w100">
                    {
                        networkList.map((item, index) => 
                            <img src={item.url} className={item.link ? 'cursor' : ''} alt="" onClick={()=> mtToLink(item)} key={index} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default NetWork